.outlined-input {
    border: 1px solid #D6D6D6;
    background-color: #fff;
}

.outlined-input .MuiAutocomplete-input {
    padding: 8px !important;
}

.error {
    font-weight: 400;
    font-size: 0.75rem;
    color: #d32f2f;
}

@media only screen and (max-width: 600px) {
    .otp-input .MuiInputBase-input {
        padding: 5px !important;
    }

    .otp-input {
        margin-left: 0px !important;
    }
}